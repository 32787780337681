import { useTranslation } from 'react-i18next';
import { ConnectisWebsiteHeroTrustedUs } from './subcomponents';

export const ConnectisWebsiteHero = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'connectisWebsite:hero',
  });

  return (
    <section className="bg-[url('assets/images/job-board/job-board-hero.png')] bg-cover bg-top h-[810px] overflow-hidden">
      <div className="w-full h-full bg-gradient-black-transparent">
        <div className="max-w-container-lg mx-auto relative h-full px-4 lg:px-12 flex flex-col justify-between">
          <div className="text-white/80 pt-40 max-w-lg md:whitespace-pre-line">
            <p className="text-2xl mb-22">{t('jobOffers')}</p>
            <h1 className="text-5xl md:text-[64px] md:leading-[64px] mb-6 bg-gradient-neon-green-up lg:bg-gradient-neon-green-down bg-clip-text text-transparent">
              {t('title')}
            </h1>
            <p>{t('subtitle')}</p>
          </div>
          <ConnectisWebsiteHeroTrustedUs />
        </div>
      </div>
    </section>
  );
};
