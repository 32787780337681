import googleSrc from 'assets/images/job-board/trustedCompanies/google.svg';
import lvmhSrc from 'assets/images/job-board/trustedCompanies/lvmh.svg';
import rockefellerSrc from 'assets/images/job-board/trustedCompanies/rockefeller.svg';
import sainsburySrc from 'assets/images/job-board/trustedCompanies/sainsbury.svg';
import santanderSrc from 'assets/images/job-board/trustedCompanies/santander.svg';
import unitedNationsSrc from 'assets/images/job-board/trustedCompanies/united-nations.svg';

export const trustedCompanies = [
  {
    src: lvmhSrc,
    name: 'lvmh',
  },
  {
    src: googleSrc,
    name: 'google',
  },
  {
    src: rockefellerSrc,
    name: 'rockefeller',
  },
  {
    src: sainsburySrc,
    name: 'sainsbury',
  },
  {
    src: unitedNationsSrc,
    name: 'unitedNations',
  },
  {
    src: santanderSrc,
    name: 'santander',
  },
];
