import dayjs from 'dayjs';

const polishHolidays = [
  '01-01',
  '01-04', // easter
  '01-05',
  '03-05',
  '30-05',
  '15-08',
  '11-11',
  '25-12',
  '26-12',
];

export const isPolishHoliday = (date: string) => {
  return polishHolidays.includes(dayjs(date).format('DD-MM'));
};
