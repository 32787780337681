import { useSitemap } from 'hooks';
import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeClasses } from 'utils';
import { PublicOfferQuery } from '../../hooks/api/publicOffer/publicOffer.generated';
import { useRegisterExpertLoginFromPublicOfferMutation } from '../../hooks/api/registerExpertLoginFromPublicOffer/registerExpertLoginFromPublicOffer.generated';
import { useAuthWithOTP } from '../../hooks/useAuthWithOTP';
import {
  ExternalOfferApplicationForm,
  ExternalOfferCodeConfirmationForm,
  ExternalOfferContactPerson,
  ExternalOfferDescription,
  ExternalOfferFinishOnboarding,
  ExternalOfferHeader,
} from './subcomponents';
import { ExternalOfferSkills } from './subcomponents/ExternalOfferSkills/ExternalOfferSkills';
import { ExternalOfferThankYou } from './subcomponents/ExternalOfferThankYou';

export type ExternalOfferType = PublicOfferQuery['publicOffer'];

enum FormSteps {
  EMAIL = 'EMAIL',
  CODE = 'CODE',
  THANKYOU = 'THANKYOU',
}

interface ExternalOfferProps {
  offer: ExternalOfferType;
  isOnOnboarding?: boolean;
  onFinishOnboardingClick?: () => void;
  displayThankYou?: boolean;
  className?: string;
}

export const ExternalOffer: FC<ExternalOfferProps> = ({
  offer,
  isOnOnboarding = false,
  displayThankYou = false,
  onFinishOnboardingClick,
  className,
}) => {
  const navigate = useNavigate();
  const sitemap = useSitemap();
  const [formStep, setFormSteps] = useState<FormSteps>(FormSteps.EMAIL);
  const [appliedExpertId, setAppliedExpertId] = useState<string | null>(null);
  const [registerExpertLoginFromPublicOffer] = useRegisterExpertLoginFromPublicOfferMutation();

  const { id, description, owner, jobOrder, isRateDisplayed, jobOrderId } = offer;
  const {
    role,
    leadingTechnologyName,
    minEnglishLevel,
    locationPreference,
    rateRangeTo,
    rateRangeFrom,
    seniority,
    workMode,
    skillsMustHave,
    skillsNiceToHave,
  } = jobOrder ?? {};

  const { onEmailSubmit, onOTPSubmit, onResendCodeClick, verifyAuthentication } = useAuthWithOTP();

  const handleApplySuccess = (expertId: string) => {
    setAppliedExpertId(expertId);
    setFormSteps(FormSteps.CODE);
  };

  const handleConfirmationSuccess = async () => {
    if (appliedExpertId) {
      await registerExpertLoginFromPublicOffer({
        variables: {
          offerId: offer.id,
          expertId: appliedExpertId,
        },
      });
    }
    if (displayThankYou) {
      setFormSteps(FormSteps.THANKYOU);
      return;
    }
    navigate(sitemap.offerApplySuccess(offer.id));
  };

  const handleOtpSubmit = (otp: string) => onOTPSubmit(otp, true, !displayThankYou);

  const handleThankYouRedirect = async () => {
    await verifyAuthentication();
    navigate(sitemap.offer(jobOrderId, id));
  };

  const currentFormComponent = useMemo(() => {
    if (formStep === FormSteps.CODE) {
      return (
        <ExternalOfferCodeConfirmationForm
          onConfirmationSuccess={handleConfirmationSuccess}
          onOTPSubmit={handleOtpSubmit}
          onResendCodeClick={() => onResendCodeClick(true)}
        />
      );
    }

    if (formStep === FormSteps.THANKYOU) {
      return <ExternalOfferThankYou onCheckOffers={handleThankYouRedirect} />;
    }

    return (
      <ExternalOfferApplicationForm
        contextContactEmail={offer.contextContactEmail}
        offerId={id}
        onApplySuccess={handleApplySuccess}
        onEmailSubmit={onEmailSubmit}
      />
    );
  }, [formStep, onOTPSubmit, onEmailSubmit]);

  return (
    <div className={mergeClasses('flex flex-col items-center', className)}>
      <div className="p-4 mb-20 lg:mb-0 lg:p-16 max-w-[100vw] lg:max-w-[1440px] w-full">
        <div className="flex flex-col lg:flex-row gap-x-14">
          <div className="flex flex-col gap-y-8 lg:gap-y-10 w-full">
            <ExternalOfferHeader
              isRateDisplayed={isRateDisplayed}
              leadingTechnologyName={leadingTechnologyName}
              locationPreference={locationPreference}
              minEnglishLevel={minEnglishLevel}
              rateFrom={rateRangeFrom}
              rateTo={rateRangeTo}
              role={role}
              seniority={seniority}
              workMode={workMode}
            />
            <div className="block lg:hidden">
              {isOnOnboarding ? (
                <ExternalOfferFinishOnboarding onFinishOnboardingClick={onFinishOnboardingClick} />
              ) : (
                currentFormComponent
              )}
            </div>
            {(!!skillsMustHave?.length || !!skillsMustHave?.length) && (
              <div>
                <p className="text-xl lg:text-2xl font-bold mb-8">{t('forms:labels.skills')}</p>
                {!!skillsMustHave?.length && (
                  <ExternalOfferSkills
                    className="mb-8"
                    isPrimary
                    skills={skillsMustHave}
                    title={t('forms:labels.required')}
                  />
                )}
                {!!skillsNiceToHave?.length && (
                  <ExternalOfferSkills
                    hideSkillLevel
                    skills={skillsNiceToHave}
                    title={t('forms:labels.preferred')}
                  />
                )}
              </div>
            )}
            {description && (
              <ExternalOfferDescription className="w-full" description={description} />
            )}
          </div>
          <div className="flex flex-col gap-y-8 lg:min-w-[424px] lg:max-w-[424px] mt-8 lg:mt-0">
            <div className="lg:block hidden">
              {isOnOnboarding ? (
                <ExternalOfferFinishOnboarding onFinishOnboardingClick={onFinishOnboardingClick} />
              ) : (
                currentFormComponent
              )}
            </div>
            <ExternalOfferContactPerson owner={owner} />
          </div>
        </div>
      </div>
    </div>
  );
};
