import { FC, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { ErrorMessage } from '../../../../Inputs/ErrorMessage';

interface FilterWrapperProps {
  title: string;
  children: ReactNode;
  error?: FieldError;
  className?: string;
}

export const FilterWrapper: FC<FilterWrapperProps> = ({ title, className, error, children }) => {
  return (
    <div>
      <p className="font-medium text-lg lg:text-2xl mb-6">{title}</p>
      <div className={className}>{children}</div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
};
