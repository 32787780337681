import ConnectisLogo from 'assets/images/connectis-logo-v2-white.svg';
import FooterBlur from 'assets/images/job-board/footer-blur.svg';
import { bottomFooterMenu, menuSchema, socials } from '../configs';
import { FooterBottomMenu, FooterMenu, FooterSocials } from './subcomponents';

export const ConnectisWebsiteFooter = () => {
  return (
    <footer className="relative bg-gradient-blue py-6 px-4 lg:px-12 pt-14 lg:pt-[100px] overflow-hidden light-section">
      <img alt="" className="absolute top-0 right-0 z-0 hidden lg:block" src={FooterBlur} />
      <div className="max-w-container-lg mx-auto z-10 relative">
        <div className="flex justify-between items-start flex-col lg:flex-row">
          <a className="mb-20" href="https://connectis.pl/">
            <img alt="" className="w-[141px] md:w-[180px] xl:w-[300px]" src={ConnectisLogo} />
          </a>
          <div className="flex flex-col md:flex-row md:space-x-6 xl:space-x-16 w-full md:w-auto">
            {menuSchema.map((menu) => (
              <FooterMenu key={menu.name} {...menu} />
            ))}
          </div>
        </div>
        <div className="pt-6 mt-16 md:border-t border-light-blue-900 flex justify-between flex-col-reverse md:flex-row">
          <FooterSocials socials={socials} />
          <FooterBottomMenu bottomMenu={bottomFooterMenu} />
        </div>
      </div>
    </footer>
  );
};
