import { Animation, IconButton, SmallButton } from 'components';
import { useScrollLock } from 'hooks';
import { t } from 'i18next';
import { ArrowIcon, CrossIcon, IconType } from 'icons';
import { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { mergeClasses } from 'utils';
import ConnectisLogo from '../../../assets/images/connectis-logo.svg';

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string | null;
  className?: string;
  children?: ReactNode;
  modalFooter?: ReactNode;
  closeModalOnBackgroundClick?: boolean;
  fromBottom?: boolean;
  iconButtonClassName?: string;
  icon?: IconType;
  backgroundClassName?: string;
  iconClassName?: string;
  footerClassName?: string;
  titleClassName?: string;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  showLogo?: boolean;
}

const modalRoot = document.getElementById('modal-root');
export const Modal: FC<ModalProps> = ({
  isOpen,
  children,
  onClose,
  title,
  className = '',
  modalFooter,
  closeModalOnBackgroundClick = true,
  fromBottom,
  iconButtonClassName,
  icon = CrossIcon,
  backgroundClassName,
  iconClassName,
  footerClassName,
  titleClassName,
  showCloseButton = true,
  showBackButton = false,
  showLogo = false,
}) => {
  const [animation, setAnimation] = useState(false);
  const { unlockPageScroll, lockPageScroll } = useScrollLock();

  useEffect(() => {
    const openedModals = modalRoot?.childNodes.length ?? 0;
    if (isOpen) {
      lockPageScroll();
    } else if (openedModals === 0) {
      unlockPageScroll();
    }
  }, [isOpen, modalRoot]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setAnimation(true), 50);
    }
    setAnimation(false);
  }, [isOpen]);

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (closeModalOnBackgroundClick && event.target === event.currentTarget && onClose) {
      onClose();
    }
  };

  return isOpen && modalRoot
    ? createPortal(
        <div
          className={mergeClasses(
            'fixed top-0 left-0 flex justify-center items-center bg-black bg-opacity-80 w-screen h-screen z-40 p-4 overflow-auto',
            backgroundClassName,
          )}
          onClick={handleBackgroundClick}
        >
          <Animation
            animationName={fromBottom ? 'slide-up' : 'fade-in-up'}
            className={mergeClasses(
              'bg-white rounded-lg p-6 relative overflow-auto max-h-[95vh]',
              className,
            )}
            isShowed={animation}
          >
            {showLogo && (
              <img alt="logo" className="w-[120px] self-center py-10" src={ConnectisLogo} />
            )}

            {onClose && showBackButton && (
              <SmallButton
                className="self-start mb-6 font-normal text-gray-500"
                icon={ArrowIcon}
                label={t('common:return')}
                labelClassName="text-[16px]"
                onClick={onClose}
              />
            )}
            {title && (
              <p className={mergeClasses('text-xl font-semibold', titleClassName)}>{title}</p>
            )}
            {onClose && showCloseButton && (
              <IconButton
                className={mergeClasses('absolute top-4 right-4 z-10', iconButtonClassName)}
                icon={icon}
                iconClassName={iconClassName}
                onClick={onClose}
              />
            )}
            {children}
            {modalFooter && (
              <div
                className={mergeClasses(
                  'bg-gray-200 bg-opacity-20 -mx-6 -mb-6 py-6 px-16',
                  footerClassName,
                )}
              >
                {modalFooter}
              </div>
            )}
          </Animation>
        </div>,
        modalRoot,
      )
    : null;
};
