import { nanoid } from 'nanoid';
import Pubnub from 'pubnub';
import { useCallback } from 'react';
import { useCreateExpertPubnubAccessDetailsMutation } from './api/createExpertPubnubAccessDetails/createExpertPubnubAccessDetails.generated';
import { useExpertNotificationsQuery } from './api/expertNotifications/expertNotifications.generated';

const pubnubClient = new Pubnub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY as string,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY as string,
  uuid: nanoid(),
});

export const usePubnub = () => {
  const [createExpertPubnubAccessDetails] = useCreateExpertPubnubAccessDetailsMutation();
  const { refetch: refetchNotifications } = useExpertNotificationsQuery();

  const subscribeChannel = useCallback((channel: string) => {
    pubnubClient.unsubscribe({
      channels: [channel],
    });

    pubnubClient.subscribe({
      channels: [channel],
    });

    pubnubClient.addListener({
      message: (message) => {
        console.log('Message from Pubnub', message);
        refetchNotifications();
      },
    });
  }, []);

  const authenticate = useCallback(() => {
    createExpertPubnubAccessDetails()
      .then((result) => {
        pubnubClient.setUUID(result.data?.createExpertPubnubAccessDetails.uuid as string);
        pubnubClient.setToken(result.data?.createExpertPubnubAccessDetails.token as string);
        subscribeChannel(result.data?.createExpertPubnubAccessDetails.channel as string);
      })
      .catch((err) => {
        console.error('Cannot create PubNub access', err);
      });
  }, [createExpertPubnubAccessDetails]);

  return {
    authenticate,
  };
};
