import { IconButton } from 'components';
import { ThinArrowIcon } from 'icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { ConnectisWebsiteMenu } from '../../configs/menu';

export interface FooterLink {
  url: string;
  labelKey: string;
}

export const FooterMenu: FC<ConnectisWebsiteMenu> = ({ name, items }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: `connectisWebsite:menus.${name}`,
  });
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="md:max-w-[200px] w-full">
      <div
        className="flex justify-between mt-6 md:mb-6 md:mt-0"
        onClick={() => setCollapsed((prev) => !prev)}
      >
        <p className="font-bold text-white">{t(`heading`)}</p>
        <IconButton
          className={mergeClasses(
            'text-light-blue-300 transition-transform duration-150 md:hidden',
            collapsed ? 'rotate-90' : '-rotate-90',
          )}
          icon={ThinArrowIcon}
        />
      </div>
      <ul
        className={mergeClasses(
          'space-y-4 overflow-hidden border-t md:border-none pt-4 md:pt-0 transition-all duration-300 md:max-h-full',
          collapsed ? 'max-h-0 border-t-light-blue-600' : 'max-h-52 border-t-transparent',
        )}
      >
        {items.map(({ url, name: itemName }) => (
          <li key={`footerMenuItem_${name}_${itemName}`} className="text-sm text-light-blue-300">
            <a href={url}>{t(`items.${itemName}.label`)}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};
