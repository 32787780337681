import { ArrowIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectisWebsiteMenuItem } from '../../../../configs/menu';

interface ConnectisWebsiteHeaderMenuItemProps extends ConnectisWebsiteMenuItem {
  menuName: string;
}

export const ConnectisWebsiteHeaderMenuItem: FC<ConnectisWebsiteHeaderMenuItemProps> = ({
  name,
  menuName,
  url,
  thumbnail,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: `connectisWebsite:menus.${menuName}.items`,
  });

  const { label, description } = t(name, {
    returnObjects: true,
  }) as { label: string; description?: string };

  return (
    <li>
      <a
        className="flex items-center space-x-3 lg:p-3 lg:pr-14 hover:bg-black/10 rounded-xl relative group"
        href={url}
      >
        {thumbnail && <img alt="" className="h-16 w-16 rounded-lg" src={thumbnail} />}
        <div>
          <p className="font-medium">{label}</p>
          {description && <p className="text-xs opacity-[75%]">{description}</p>}
        </div>
        <span className="bg-neon-green-500 rounded-full rotate-180 w-6 h-6 text-black absolute right-4 lg:right-8 hidden group-hover:inline">
          <ArrowIcon />
        </span>
      </a>
    </li>
  );
};
