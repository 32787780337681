import loadScript from 'load-script';
import { useEffect, useState } from 'react';

let isLoaded = false;
let isLoading = false;
export const useGoogleAutocompleteService = () => {
  const [autocompleteService, setAutocompleteService] = useState<any>(null);
  const [places, setPlaces] = useState([]);

  const handleSetServiceFromWindow = () => {
    if (autocompleteService || !(window as any)?.google?.maps?.places?.AutocompleteService) return;
    const service = new (window as any).google.maps.places.AutocompleteService();
    setAutocompleteService(service);
    isLoaded = true;
  };

  const searchPlace = (value: string) => {
    if (!autocompleteService) return handleSetServiceFromWindow();
    autocompleteService.getPlacePredictions(
      {
        input: value,
        types: ['(cities)'],
      },
      (res: any) => {
        setPlaces(res);
      },
    );
  };

  useEffect(() => {
    if ((window as any).google) return handleSetServiceFromWindow();
    if (isLoading || isLoaded) return;
    isLoading = true;
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API}&libraries=places&&callback=Function.prototype`,
      { async: true },
      handleSetServiceFromWindow,
    );
  }, []);

  return { searchPlace, places, isLoaded };
};
