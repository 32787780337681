import { Animation, NumberInput, Radio } from 'components';
import { ErrorMessage } from 'components/Inputs/ErrorMessage';
import { ExpertAvailabilityEnum } from 'interfaces';
import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDayLabels, getMonthLabels } from 'utils';
import useMobile from '../../hooks/useMobile';
import { RadioItem } from '../Inputs/Radio/RadioItem';
import AnimatedAutoHeight from './subcomponents/AnimatedAutoHeight';

interface AvailabilitySelectProps {
  availabilityValue: string;
  availabilityError?: FieldError;
  noticeLengthValue?: number;
  noticeLengthError?: FieldError;
  onAvailabilityChange: (value: string) => void;
  onNoticeLengthChange: (value: number) => void;
  className?: string;
}

const noticeLengthUnits = [ExpertAvailabilityEnum.Days, ExpertAvailabilityEnum.Months];
export const AvailabilitySelect: FC<AvailabilitySelectProps> = ({
  availabilityValue,
  noticeLengthValue,
  onNoticeLengthChange,
  onAvailabilityChange,
  availabilityError,
  noticeLengthError,
  className,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();

  const noticeLengthOptions = noticeLengthUnits.map((el) => ({
    label: t(`dictionaries:availability.${el}`),
    value: el,
  }));

  const isNumberPickerShowed =
    !!availabilityValue && availabilityValue !== ExpertAvailabilityEnum.Immediately;

  return (
    <div className={className}>
      <AnimatedAutoHeight>
        <RadioItem
          className="!mb-2"
          id="immediately"
          isChecked={availabilityValue === ExpertAvailabilityEnum.Immediately}
          isError={!!availabilityError}
          label={t(`dictionaries:availability.Immediately`)}
          name="availability_immediately"
          onChange={() => onAvailabilityChange(ExpertAvailabilityEnum.Immediately)}
        />
        <RadioItem
          id="notice"
          isChecked={noticeLengthUnits.includes(availabilityValue as ExpertAvailabilityEnum)}
          isError={!!availabilityError}
          label={t(`dictionaries:availability.Notice`)}
          name="availability_notice"
          onChange={() => onAvailabilityChange(ExpertAvailabilityEnum.Days)}
        />
        <ErrorMessage error={availabilityError} />
      </AnimatedAutoHeight>
      <Animation
        animationName="fade-in-up"
        className="flex flex-col md:block"
        isShowed={isNumberPickerShowed}
      >
        <Radio
          className="mt-2 items-end !w-full md:items-stretch"
          columns={isMobile ? 1 : 2}
          error={availabilityError}
          name="availability"
          onChange={onAvailabilityChange}
          options={noticeLengthOptions}
          radioItemClassName="place-self-end w-11/12 md:w-full"
          value={availabilityValue}
        />
        <NumberInput
          className="mt-2 w-11/12 md:w-full place-self-end md:item-stretch "
          currency={
            availabilityValue === ExpertAvailabilityEnum.Days
              ? getDayLabels(t, noticeLengthValue ?? 0)
              : getMonthLabels(t, noticeLengthValue ?? 0)
          }
          error={noticeLengthError}
          min={1}
          name="noticeLength"
          onChange={onNoticeLengthChange}
          value={noticeLengthValue ?? 0}
        />
      </Animation>
    </div>
  );
};
