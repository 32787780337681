import { IconType } from 'icons';
import { FC } from 'react';

export interface FooterSocial {
  name: string;
  icon: IconType;
  url: string;
}

interface FooterSocialsProps {
  socials: FooterSocial[];
}

export const FooterSocials: FC<FooterSocialsProps> = ({ socials }) => {
  return (
    <ul className="flex space-x-3">
      {socials.map(({ icon: Icon, url, name }) => (
        <li
          key={name}
          className="flex justify-center items-center border border-light-blue-400 text-light-blue-400 rounded-full"
        >
          <a className="p-2.5" href={url}>
            <Icon className="w-6 h-6" />
          </a>
        </li>
      ))}
    </ul>
  );
};
