import { SearchInput, Skill } from 'components';
import { ExpertLanguage, LanguageLevelEnum } from 'interfaces';
import { useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOnboardingDataQuery } from '../../../hooks/api/onboardingData/onboardingData.generated';
import { AddItemButton } from '../subcomponents';
import { SkillLevel } from './SkillsStep';

export type LanguageSkillLevelType = null | 1 | 2 | 3 | 4 | 5 | 6;
export const englishLanguageId = process.env.REACT_APP_DEFAULT_LANGUAGE_ID;

export const languagesLevelDisc = {
  0: LanguageLevelEnum.None,
  1: LanguageLevelEnum.Starter,
  2: LanguageLevelEnum.Elementary,
  3: LanguageLevelEnum.PreIntermediate,
  4: LanguageLevelEnum.Intermediate,
  5: LanguageLevelEnum.UpperIntermediate,
  6: LanguageLevelEnum.Advanced,
};

export interface LanguagesStepValues {
  languages: ExpertLanguage[];
}

export const LanguagesStep = () => {
  const { t } = useTranslation();

  const { data } = useOnboardingDataQuery();
  const { languages } = data ?? { languages: [] };
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<LanguagesStepValues>();

  const languagesValue = watch('languages') ?? [];

  const languagesOptions = languages
    .filter((lang) => !languagesValue.find(({ languageId }) => lang.languageId === languageId))
    .map(({ languageId, name }) => ({
      label: name,
      value: languageId,
    }));

  const handleAddLanguage = (languageId: string) => {
    const level =
      languageId === englishLanguageId ? LanguageLevelEnum.None : LanguageLevelEnum.Starter;
    setValue('languages', [
      ...languagesValue,
      {
        languageId,
        level,
      },
    ]);
  };

  const handleRemoveLanguage = (languageId: string) => {
    setValue(
      'languages',
      languagesValue.filter((lang) => lang.languageId !== languageId),
    );
  };

  const handleLanguageLevelChange = (languageId: string, newLevel: SkillLevel) => {
    setValue(
      'languages',
      languagesValue.map((lang) =>
        lang.languageId === languageId
          ? { ...lang, level: languagesLevelDisc[newLevel ?? 1] }
          : lang,
      ),
    );
  };

  useEffect(() => {
    if (!languages.length || !englishLanguageId) return;

    const isEnglishAlreadyAdded = !!languagesValue.find(
      ({ languageId }) => languageId === englishLanguageId,
    );
    if (isEnglishAlreadyAdded) return;
    handleAddLanguage(englishLanguageId);
  }, [languages, languagesValue]);

  return (
    <div className="w-full m-0 md:mt-6 lg:mt-14">
      <div className="flex md:grid flex-col md:grid-cols-3 gap-2 md:gap-8 mt-4 md:mt-14 mb-32 lg:mb-16">
        <div className="w-full md:hidden">
          <SearchInput onChange={handleAddLanguage} options={languagesOptions} />
          <hr className="block my-6" />
        </div>
        {languagesValue?.map(({ languageId, level }, index) => {
          const errorString =
            level === LanguageLevelEnum.None && errors?.languages
              ? errors?.languages[index]?.level?.message ?? ''
              : undefined;
          const error: FieldError | undefined = errorString
            ? { type: 'custom', message: errorString }
            : undefined;
          return (
            <Skill<LanguageSkillLevelType>
              key={`languageItem_${languageId}`}
              className="min-h-min md:min-h-[125px] order-2 md:order-1"
              dotsNumber={6}
              error={error}
              id={languageId}
              level={Object.values(languagesLevelDisc).indexOf(level) as SkillLevel}
              levelDiscName="languageLevels"
              name={languages.find((lang) => lang.languageId === languageId)?.name ?? languageId}
              onLevelChange={handleLanguageLevelChange}
              onRemove={handleRemoveLanguage}
            />
          );
        })}
        <AddItemButton
          centerButtonForGrid
          label={t('onboarding:languagesStep.addLanguage')}
          onChange={handleAddLanguage}
          options={languagesOptions}
        />
      </div>
    </div>
  );
};
