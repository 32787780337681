import { LanguageLevelEnum, SeniorityLevelEnum, WorkModeEnum } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { determineRequiredLanguageLevel } from '../../../Modals/OfferDetailsModal/subcomponents/JobOrderDetailsHeader';
import { ExternalOfferCTASection } from '../ExternalOfferCTASection';
import { ExternalOfferHeaderDetail, ExternalOfferHeaderRate } from './subcomponents';

interface ExternalOfferHeaderProps {
  role?: string | null;
  leadingTechnologyName?: string | null;
  rateFrom?: number | null;
  rateTo?: number | null;
  locationPreference?: string | null;
  workMode?: WorkModeEnum | null;
  seniority?: SeniorityLevelEnum | null;
  minEnglishLevel?: LanguageLevelEnum | null;
  isRateDisplayed: boolean;
  className?: string;
}

export const ExternalOfferHeader = ({
  role,
  leadingTechnologyName,
  rateFrom,
  rateTo,
  locationPreference,
  workMode,
  seniority,
  minEnglishLevel,
  isRateDisplayed,
  className,
}: ExternalOfferHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={mergeClasses('p-6 shadow-lg rounded-3xl', className)}>
      <p className="text-2xl lg:text-[40px] font-bold mb-6">
        {role} {leadingTechnologyName}
      </p>
      {isRateDisplayed && <ExternalOfferHeaderRate rateFrom={rateFrom || 0} rateTo={rateTo || 0} />}
      <div className="flex flex-wrap gap-x-6 gap-y-1 pb-6 mb-6 border-b border-gray-200">
        <ExternalOfferHeaderDetail label={t('forms:labels.location')} value={locationPreference} />
        <ExternalOfferHeaderDetail
          label={t('forms:labels.workType')}
          value={workMode ? t(`dictionaries:workTypes.${workMode}`) : '-'}
        />
        <ExternalOfferHeaderDetail label={t('forms:labels.seniority')} value={seniority} />
        <ExternalOfferHeaderDetail
          label={t('forms:labels.foreignLanguage')}
          value={
            minEnglishLevel
              ? `${t('forms:labels.english')} ${determineRequiredLanguageLevel(minEnglishLevel)}`
              : '-'
          }
        />
      </div>
      <ExternalOfferCTASection />
    </div>
  );
};
