export const bottomFooterMenu = [
  {
    labelKey: 'officeConnectis',
    url: 'mailto:office.pl@connectis.pl',
  },
  {
    labelKey: 'privacy',
    url: 'https://connectis.pl/',
  },
  {
    labelKey: 'termsOfService',
    url: 'https://connectis.pl/',
  },
];
