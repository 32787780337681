import { useOnClickOutside } from 'hooks';
import { AngleIcon, ArrowIcon } from 'icons';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

const ListItem: FC<{ label: string; href: string }> = ({ label, href }) => {
  return (
    <li className="flex items-center text-light-blue-300 hover:text-white py-2 space-x-6 w-[204px] relative group">
      <a href={href}>
        <span>{label}</span>
        <span className="bg-neon-green-500 rounded-full rotate-180 w-6 h-6 text-black absolute right-0 hidden group-hover:inline">
          <ArrowIcon />
        </span>
      </a>
    </li>
  );
};

export const ConnectisWebsiteHeaderSignInButton = () => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'connectisWebsite:signInButton',
  });

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div ref={ref} className="relative">
      <button
        className="bg-primary-500 py-1 px-4 flex items-center whitespace-nowrap text-white rounded-full space-x-1"
        onClick={() => setOpen((prev) => !prev)}
      >
        <span>{t('signIn')}</span>
        <AngleIcon className="w-4" />
      </button>
      <ul
        className={mergeClasses(
          'px-5 py-3 bg-primary-500 rounded-2xl absolute top-10 right-0',
          open ? 'block' : 'hidden',
        )}
      >
        <ListItem href="https://connectis.pl/" label={t('iAmExpert')} />
        <ListItem href="https://connectis.pl/" label={t('iAmClient')} />
      </ul>
    </div>
  );
};
