import { JsonObject } from 'type-fest';

export const notifications: JsonObject = {
  matchScoreNotification: {
    offerAdded: 'Dodano nową ofertę dla której jesteś dopasowany na',
    checkOffer: 'sprawdź i umów się na spotkanie',
  },
  applicationStatusChangeNotification: {
    statusChanged: 'Zmienił się status Twojej aplikacji na stanowisko',
  },
  noNotifications: 'Nie posiadasz żadnych notyfikacji',
  offers: 'Oferty',
  application: 'Moje aplikacje',
};
