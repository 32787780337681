import {
  ConnectisWebsiteFooter,
  ConnectisWebsiteHeader,
  ConnectisWebsiteHero,
  ConnectisWebsiteHubSection,
  JobBoard,
} from 'components';
import { useLightBackgroundObserver } from 'hooks';

export const JobBoardPage = () => {
  const { isLightBackground, measureDimensions } = useLightBackgroundObserver();

  return (
    <main className="font-dmSans">
      <ConnectisWebsiteHeader isLightBackground={isLightBackground} />
      <ConnectisWebsiteHero />
      <ConnectisWebsiteHubSection />
      <section
        className="max-w-container-lg px-4 md:px-12 flex justify-center mx-auto py-12 md:py-30 light-section"
        id="jobBoard"
      >
        <JobBoard onPublicOffersFetch={measureDimensions} />
      </section>
      <ConnectisWebsiteFooter />
    </main>
  );
};
