import {
  ApolloClient,
  ApolloProvider,
  concat,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuthorizationToken } from 'hooks';
import { ReactNode } from 'react';

interface GraphQLProviderProps {
  children: ReactNode;
}

const createApolloClient = () => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject: (object) => {
        // eslint-disable-next-line no-underscore-dangle
        switch (object.__typename) {
          case 'ExpertTagType':
            return Math.random().toString();
          default:
            return defaultDataIdFromObject(object);
        }
      },
    }),
    link: concat(
      setContext(async (context) => {
        const authorizationToken = await getAuthorizationToken();

        if (!authorizationToken) {
          return context;
        }

        return {
          ...context,
          uri: `${process.env.REACT_APP_API_URL}?operation=${context.operationName}`,
          headers: {
            authorization: `Bearer ${authorizationToken}`,
          },
        };
      }),
      httpLink,
    ),
  });
};

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  return <ApolloProvider client={createApolloClient()}>{children}</ApolloProvider>;
};
