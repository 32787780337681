import { RoundedButton } from 'components';
import { useTranslation } from 'react-i18next';

export const PortfolioButton = () => {
  const { t } = useTranslation();

  return (
    <button className="flex items-center justify-center space-x-2 border-t lg:border-t-0 border-t-white/10 w-full lg:w-auto mt-6 md:mt-0">
      <p className="font-medium whitespace-nowrap">{t('connectisWebsite:hero.ourPortfolio')}</p>
      <RoundedButton arrowSize="10" variant="neon" />
    </button>
  );
};
