const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

export const useRecaptcha = () => {
  const executeRecaptcha = async (actionName: string, callback: (token: string) => void) => {
    if (!recaptchaKey) {
      throw new Error('Missing Recaptcha key');
    }

    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(recaptchaKey, {
        action: actionName,
      });
      callback(token);
    });
  };

  return {
    executeRecaptcha,
  };
};
