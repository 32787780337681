import { CircularProgress } from 'components';
import { t } from 'i18next';
import {
  ExpertAvailabilityEnum,
  LanguageLevelEnum,
  MaxAvailabilityEnum,
  WorkModeEnum,
} from 'interfaces';
import { FC } from 'react';
import { JobOrderScoreCategoryEnum } from '../../../../interfaces/jobOrderScoreCategories';
import { determineRequiredLanguageLevel } from './JobOrderDetailsHeader';

export const MaxAvailabilityDictionary: { [key: string]: string } = {
  ASAP: 'ASAP',
  Month: 'Miesiąc',
  MoreThanTwoMonths: '2 miesiące lub więcej',
};

interface SkillsLabelProps {
  category: JobOrderScoreCategoryEnum;
  skillScore: number;
  minEnglishLevel?: LanguageLevelEnum | null | undefined;
  expertEnglish?: LanguageLevelEnum;
  rateRangeFrom?: number | null;
  rateRangeTo?: number | null;
  expertSalary?: number | null;
  requiredWorkType?: WorkModeEnum | null;
  preferredWorkType?: WorkModeEnum | null;
  requiredAvailability?: MaxAvailabilityEnum | null;
  preferredAvailability?: ExpertAvailabilityEnum | null;
  noticeLength?: number | null;
}

export const SkillsLabel: FC<SkillsLabelProps> = ({
  category,
  skillScore,
  minEnglishLevel,
  expertEnglish,
  rateRangeFrom,
  rateRangeTo,
  expertSalary,
  requiredWorkType,
  preferredWorkType,
  requiredAvailability,
  preferredAvailability,
  noticeLength,
}) => {
  const minimalScoreToApply = process.env.REACT_APP_MINIMAL_SCORE_TO_APPLY ?? 0;
  const isGreen = skillScore >= minimalScoreToApply;
  const isBlue = skillScore < 79 && skillScore >= 50;

  const roundedRateRangeFrom = rateRangeFrom ? Math.round(rateRangeFrom) : 0;
  const roundedRateRangeTo = rateRangeTo ? Math.round(rateRangeTo) : 0;
  const determineSkillDescription = () => {
    if (category === JobOrderScoreCategoryEnum.RequiredSkills) {
      return t('forms:matchingValuation.requiredSkills');
    }
    if (category === JobOrderScoreCategoryEnum.PreferredSkills) {
      if (isGreen) {
        return t('forms:matchingValuation:preferredSkills.fulfilled');
      }
      if (isBlue) {
        return t('forms:matchingValuation:preferredSkills.incomplete');
      }
      return t('forms:matchingValuation:preferredSkills.insufficient');
    }
  };

  const isSkillWithDescription =
    category === JobOrderScoreCategoryEnum.RequiredSkills ||
    category === JobOrderScoreCategoryEnum.PreferredSkills;

  const determineRequiredSkillLevel = () => {
    if (category === JobOrderScoreCategoryEnum.Languages) {
      return determineRequiredLanguageLevel(minEnglishLevel);
    }
    if (category === JobOrderScoreCategoryEnum.Salary) {
      return `${roundedRateRangeFrom}-${roundedRateRangeTo} PLN`;
    }
    if (category === JobOrderScoreCategoryEnum.WorkMode) {
      return t(`dictionaries:workTypes.${requiredWorkType}`);
    }
    if (category === JobOrderScoreCategoryEnum.Availability && requiredAvailability) {
      return MaxAvailabilityDictionary[requiredAvailability];
    }
  };

  const determinePreferredSkillLevel = () => {
    if (category === JobOrderScoreCategoryEnum.Languages) {
      return determineRequiredLanguageLevel(expertEnglish);
    }
    if (category === JobOrderScoreCategoryEnum.Salary) {
      return `${expertSalary} PLN`;
    }
    if (category === JobOrderScoreCategoryEnum.WorkMode) {
      return t(`dictionaries:workTypes.${preferredWorkType}`);
    }
    if (category === JobOrderScoreCategoryEnum.Availability) {
      if (preferredAvailability === 'Months' || preferredAvailability === 'Days') {
        return `${t(`dictionaries:availability.${preferredAvailability}`)} (${noticeLength})`;
      }
      return t(`dictionaries:availability.${preferredAvailability}`);
    }
  };

  return (
    <div className="flex gap-4 transition-all">
      <CircularProgress
        className="w-[55px] lg:w-[70px] h-[55px] lg:h-[70px] !min-w-[55px] lg:!min-w-[70px]"
        textClassName="text-black text-[12px] leading-[15px] lg:text-[14px]"
        value={skillScore ?? 0}
      />
      <div className="w-fit">
        <p className="flex flex-row gap-2 text-[14px] leading-[22.5px] font-medium lg:w-full">
          {category}
        </p>
        {isSkillWithDescription ? (
          <div className="text-[14px] leading-[22.5px] pt-1">{determineSkillDescription()}</div>
        ) : (
          <>
            <div className="text-[14px] leading-[22.5px] pt-1 flex flex-wrap gap-1 lg:flex-col xl:flex-row">
              <span className="whitespace-nowrap">
                {category === JobOrderScoreCategoryEnum.Salary
                  ? t('forms:matchingValuation:otherSkills.proposed')
                  : t('forms:matchingValuation:otherSkills.required')}
              </span>
              <p className="font-semibold first-letter:capitalize">
                {determineRequiredSkillLevel()}
              </p>
            </div>
            <div className="text-[14px] leading-[22.5px] flex flex-wrap gap-1 lg:flex-col xl:flex-row">
              <span className="whitespace-nowrap">
                {t('forms:matchingValuation:otherSkills.preferred')}
              </span>
              <p className="font-semibold break-words first-letter:capitalize ">
                {determinePreferredSkillLevel()}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
