import { TFunction } from 'i18next';
import { ExpertTag, Shape } from 'interfaces';
import * as yup from 'yup';
import { validatePhoneNumber } from '../Inputs/PhoneNumberInput/utils';
import {
  basicInformationLimits,
  MAX_FILE_SIZE,
  MAX_NAME_CHARACTERS,
  NO_SPECIAL_CHARACTERS,
} from '../Onboarding/utils/inputLimitations';
import { ProfileValues } from './interfaces';

export const profileValidationSchema = (t: TFunction) => {
  return yup.object().shape<Shape<ProfileValues>>({
    firstName: yup
      .string()
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.firstName').toLowerCase(),
        }) ?? '',
      )
      .max(
        MAX_NAME_CHARACTERS,
        t('forms:validationMessages.atMax', {
          fieldName: t('forms:labels.firstName').toLowerCase(),
          max: 50,
        }) ?? '',
      )
      .test('match', t('forms:validationMessages.wrongFormat') as string, function (value) {
        return NO_SPECIAL_CHARACTERS.test(value ?? '');
      }),

    lastName: yup
      .string()
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.lastName').toLowerCase(),
        }) ?? '',
      )
      .max(
        MAX_NAME_CHARACTERS,
        t('forms:validationMessages.atMax', {
          fieldName: t('forms:labels.lastName').toLowerCase(),
          max: 50,
        }) ?? '',
      )
      .test('match', t('forms:validationMessages.wrongFormat') as string, function (value) {
        return NO_SPECIAL_CHARACTERS.test(value ?? '');
      }),

    expertCv: yup
      .mixed()
      .test('fileSize', t('forms:validationMessages.fileTooBig', { max: '5MB' }) ?? '', (value) => {
        if (!value) return true;
        return value.size <= MAX_FILE_SIZE;
      }),

    experienceYears: yup
      .number()
      .typeError(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.experience').toLowerCase(),
        }) ?? '',
      )
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.experience').toLowerCase(),
        }) ?? '',
      )
      .min(
        0,
        t('forms:validationMessages.noNegativeValues', {
          min: 0,
          fieldName: t('forms:labels.experience').toLowerCase(),
        }) ?? '',
      )
      .max(
        99,
        t('forms:validationMessages.maxExperience', {
          max: 99,
          fieldName: t('forms:labels.experience').toLowerCase(),
        }) ?? '',
      ),
    tags: yup.array().test(
      'atLeast3Filled',
      t('forms:validationMessages.atLeast', {
        min: 3,
        fieldName: t('forms:labels.technologies').toLowerCase(),
      }) ?? '',
      (tags?: ExpertTag[]) => {
        if (!tags) return false;
        const filledTags = tags.filter(({ weight }) => weight).length;
        return filledTags >= 3;
      },
    ),
    location: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.location').toLowerCase(),
      }) ?? '',
    ),
    workType: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.workType').toLowerCase(),
      }) ?? '',
    ),
    rateDesired: yup
      .number()
      .typeError(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.rateDesired').toLowerCase(),
        }) ?? '',
      )
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.rateDesired').toLowerCase(),
        }) ?? '',
      )
      .min(
        basicInformationLimits.MIN_HOURLY_RATE,
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.rateDesired').toLowerCase(),
        }) ?? '',
      )
      .max(
        basicInformationLimits.MAX_HOURLY_RATE,
        t('forms:validationMessages.maxSalary', {
          fieldName: t('forms:labels.rateDesired').toLowerCase(),
          max: basicInformationLimits.MAX_HOURLY_RATE,
        }) ?? '',
      ),
    availability: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.availability'),
      }) ?? '',
    ),
    githubUrl: yup
      .string()
      .nullable()
      .url(t('forms:validationMessages.urlNotValid') ?? ''),
    linkedInUrl: yup
      .string()
      .nullable()
      .url(t('forms:validationMessages.urlNotValid') ?? ''),
    phoneNumber: yup
      .string()
      .nullable()
      .test(
        'isValidPhoneNumber',
        t('forms:validationMessages.phoneNumberNotValid') ?? '',
        (value?: string | null) => (value ? validatePhoneNumber(value) : true),
      ),
  });
};
