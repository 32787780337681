import { useOnClickOutside, useWindowListener } from 'hooks';
import { AngleIcon } from 'icons';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { mergeClasses } from 'utils';
import { menuSchema } from '../../../configs';
import { ConnectisWebsiteHeaderMenuItem } from './subcomponents';
import { ConnectisWebsiteHeaderSidePanel } from './subcomponents/ConnectisWebsiteHeaderSidePanel';

export const ConnectisWebsiteHeaderMenu: FC<{ isScrollTop: boolean }> = ({ isScrollTop }) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const isCollapsed = selectedIndex === null;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'connectisWebsite:menus',
  });

  const handleClearIndex = useCallback(() => setSelectedIndex(null), []);

  useOnClickOutside(wrapperRef, handleClearIndex);
  useWindowListener({
    scroll: handleClearIndex,
  });

  const handleItemClick = (index: number) =>
    setSelectedIndex((prev) => (prev !== index ? index : null));

  const selectedMenu = useMemo(
    () => (selectedIndex !== null ? menuSchema[selectedIndex] : null),
    [selectedIndex],
  );
  const items = selectedMenu?.items.filter(({ header }) => header !== false) ?? [];

  return (
    <div ref={wrapperRef} className="hidden xl:block">
      <ul className="flex peer z-10 relative">
        {menuSchema.map(({ name }, index) => (
          <li
            key={`menu-item-${name}`}
            className="flex items-center text-sm space-x-1 [&:not(:last-child)]:pr-8 group h-full py-4 cursor-pointer"
            onClick={() => handleItemClick(index)}
          >
            <span>{t(`${name}.heading`)}</span>
            <AngleIcon
              className={mergeClasses(
                'w-5 transition-transform duration-500',
                selectedIndex === index ? 'rotate-180' : '',
              )}
            />
          </li>
        ))}
      </ul>
      <div
        className={mergeClasses(
          'absolute top-0 left-0 transition-all duration-1000 w-screen z-0 overflow-hidden',
          isCollapsed ? 'h-14' : 'h-96',
          isScrollTop || !isCollapsed ? 'backdrop-blur-2xl bg-white/10' : 'backdrop-blur-none',
        )}
      >
        {selectedMenu && (
          <SwitchTransition>
            <CSSTransition
              key={selectedMenu.name}
              addEndListener={(done: any) =>
                nodeRef?.current?.addEventListener('transitionend', done, false)
              }
              classNames="fade"
              nodeRef={nodeRef}
            >
              <div
                ref={nodeRef}
                className="grid grid-cols-3 gap-8 mt-14 px-4 lg:px-12 max-w-container-lg mx-auto"
              >
                <ul className="grid grid-cols-2 gap-8 w-full col-span-2 py-6">
                  {items.map((item) => (
                    <ConnectisWebsiteHeaderMenuItem
                      key={`${selectedMenu.name}${item.name}`}
                      {...item}
                      menuName={selectedMenu.name}
                    />
                  ))}
                </ul>
                {selectedMenu.portfolioProject && (
                  <ConnectisWebsiteHeaderSidePanel {...selectedMenu.portfolioProject} />
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        )}
      </div>
    </div>
  );
};
