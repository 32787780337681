import { useReactiveVar } from '@apollo/client';
import { JobOrdersQuerySortByEnum } from 'interfaces';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { profileVar } from 'reactive-vars';
import { ActiveApplicationJobOrdersDocument } from './api/activeApplicationJobOrders/activeApplicationJobOrders.generated';
import {
  ApplyForJobOrderMutation,
  useApplyForJobOrderMutation,
} from './api/applyForJobOrder/applyForJobOrder.generated';
import { EndedApplicationJobOrdersDocument } from './api/endedApplicationJobOrders/endedApplicationJobOrders.generated';
import { ExpertApplicationCountDocument } from './api/expertApplicationCount/expertApplicationCount.generated';
import { JobOrdersUnderMinScoreToApplyDocument } from './api/jobOrdersUnderMinScoreToApply/jobOrdersUnderMinScoreToApply.generated';
import { MostRecommendedOfferDocument } from './api/mostRecommendedOffer/mostRecommendedOffer.generated';
import { PublicOfferAppliedJobOrdersDocument } from './api/publicOfferAppliedJobOrders/publicOfferAppliedJobOrders.generated';
import { RecommendedJobOrdersDocument } from './api/recommendedJobOrders/recommendedJobOrders.generated';
import { SavedJobOrdersDocument } from './api/savedJobOrders/savedJobOrders.generated';
import { useToggleExpertSavedJobOrderMutation } from './api/toggleExpertSavedJobOrder/toggleExpertSavedJobOrder.generated';
import { useModalState } from './useModalState';

type ApplyForJobOrderApplicationType = ApplyForJobOrderMutation['applyForJobOrder'];

const { CreatedAtAsc, CreatedAtDesc, ...otherSortTypes } = JobOrdersQuerySortByEnum;
export const sortTypes = Object.values(otherSortTypes);

export const useJobOrderModalsHandlers = (refetchFn?: () => void) => {
  const { expertId } = useReactiveVar(profileVar) ?? {};
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [sortBy, setSortBy] = useState(sortTypes[0]);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const {
    modalState: detailsModalState,
    closeModal: closeDetailsModal,
    openModal: openDetailsModal,
  } = useModalState<string | null>();

  const {
    modalState: expiredOfferModalState,
    closeModal: closeExpiredOfferModal,
    openModal: openExpiredOfferModal,
  } = useModalState<string | null>();

  const {
    modalState: calendarModalState,
    closeModal: closeCalendarModal,
    openModal: openCalendarModal,
  } = useModalState<string | null>();
  const errorToast = () => toast.error(t('toasts:error'));
  const [toggleSavedJobOrderMutation] = useToggleExpertSavedJobOrderMutation({
    onError: errorToast,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SavedJobOrdersDocument,
      },
      {
        query: RecommendedJobOrdersDocument,
      },
      {
        query: PublicOfferAppliedJobOrdersDocument,
      },
      {
        query: JobOrdersUnderMinScoreToApplyDocument,
        variables: {
          params: {
            sortBy: sortBy || JobOrdersQuerySortByEnum.BestMatch,
          },
        },
      },
    ],
  });
  const [applyForJobOrderMutation] = useApplyForJobOrderMutation({
    onError: errorToast,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ActiveApplicationJobOrdersDocument,
      },
      {
        query: EndedApplicationJobOrdersDocument,
      },
      {
        query: RecommendedJobOrdersDocument,
      },
      {
        query: SavedJobOrdersDocument,
      },
      {
        query: PublicOfferAppliedJobOrdersDocument,
      },
      {
        query: MostRecommendedOfferDocument,
      },
      {
        query: ExpertApplicationCountDocument,
      },
      {
        query: JobOrdersUnderMinScoreToApplyDocument,
        variables: {
          params: {
            sortBy: sortBy || JobOrdersQuerySortByEnum.BestMatch,
          },
        },
      },
    ],
  });

  const handleFavouriteClick = async (jobOrderId: string) => {
    if (!expertId) return;

    await toggleSavedJobOrderMutation({
      variables: { expertId, jobOrderId },
    });
    if (refetchFn) {
      refetchFn();
    }

    toast.success(t('offers:offerListItem.offerAddedToSaved'));
  };

  const applyForJobOrder = async (
    meetingDates: string[],
    finalScore?: number,
    publicOfferId?: string,
  ): Promise<ApplyForJobOrderApplicationType | null> => {
    const joId = calendarModalState.data;
    if (!joId) return null;

    if (!finalScore) {
      return null;
    }
    const res = await applyForJobOrderMutation({
      variables: {
        jobOrderId: joId,
        meetingDates,
        finalScore,
        publicOfferId: publicOfferId ?? null,
      },
    });

    const application = res.data?.applyForJobOrder || null;

    if (application) {
      toast.success(t('offers:offerListItem.applyForJobOrderSuccess'));
    }

    return application;
  };

  const closeDetailsModalAndLeaveSearchParams = () => {
    closeDetailsModal();
    document.body.style.overflow = 'auto';
  };

  const closeDetailsModalAndClearJobOrderId = () => {
    if (searchParams.has('jobOrderId')) {
      navigate(pathname);
      searchParams.delete('jobOrderId');
    }
    closeDetailsModal();
    document.body.style.overflow = 'auto';
  };

  const handleScheduleMeetingClick = (jobOrderId: string) => {
    closeDetailsModalAndClearJobOrderId();
    document.body.style.overflow = 'auto';
    openCalendarModal(jobOrderId);
  };

  const closeExpiredOfferModalAndClearJobOrderId = () => {
    if (searchParams.has('jobOrderId')) {
      navigate(pathname);
      searchParams.delete('jobOrderId');
    }
    closeExpiredOfferModal();
    document.body.style.overflow = 'auto';
  };

  return {
    toggleSavedJobOrderMutation,
    applyForJobOrder,
    handleFavouriteClick,
    handleScheduleMeetingClick,
    closeDetailsModalAndClearJobOrderId,
    closeExpiredOfferModalAndClearJobOrderId,
    closeDetailsModalAndLeaveSearchParams,
    closeCalendarModal,
    closeExpiredOfferModal,
    closeDetailsModal,
    openDetailsModal,
    openCalendarModal,
    openExpiredOfferModal,
    detailsModalState,
    expiredOfferModalState,
    calendarModalState,
    expertId,
  };
};
