import { JsonObject } from 'type-fest';

export const connectisWebsite: JsonObject = {
  hero: {
    jobOffers: 'Oferty pracy',
    title: 'Wybierz projekt \ni\u00A0dołącz do nas',
    subtitle:
      'Szukasz nowych wyzwań zawodowych? Sprawdź nasze oferty pracy\n i znajdź stanowisko idealne dla siebie. Dołącz do zespołu, który ceni innowacyjność i wspiera Twój rozwój.',
    trustedUs: 'Zaufali nam',
    ourPortfolio: 'Nasze portfolio',
  },
  hubSection: {
    title: 'Aplikuj z C_hub',
    description:
      'C_hub to platforma, która dopasuje oferty pracy na podstawie Twojego doświadczenia i preferencji.\n Przyspiesz proces rekrutacji i umów się na spotkanie!',
    joinHub: 'Dołącz do C_hub',
    startWithHub: 'Tutaj zaczynasz z C_hub',
    step: 'Krok',
    steps: [
      'Znalezienie oferty',
      'Przesłanie zgłoszenia',
      'Analiza zgłoszenia',
      'Rozmowa z Connectis',
      'Rozmowa techniczna',
      'Zatrudnienie',
    ],
  },
  menus: {
    ourServices: {
      heading: 'Nasze usługi',
      items: {
        generativeAI: {
          label: 'Generative AI',
          description: 'Przyśpiesz inicjatywy dotyczące rozwoju oprogramowania.',
        },
        dataAndMLAI: {
          label: 'Data & ML/AI',
          description: 'Przyśpiesz inicjatywy dotyczące rozwoju oprogramowania.',
        },
        applicationEngineeringServices: {
          label: 'Application Engineering Services',
          description: 'Przyśpiesz inicjatywy dotyczące rozwoju oprogramowania.',
        },
        cloudServices: {
          label: 'Cloud Services',
          description: 'Przyśpiesz inicjatywy dotyczące rozwoju oprogramowania.',
        },
        hireAnExpert: {
          label: 'Zatrudnij Eksperta',
          description: 'Przyśpiesz inicjatywy dotyczące rozwoju oprogramowania.',
        },
      },
    },
    companies: {
      heading: 'Dla firm',
      items: {
        hireAnExpert: {
          description:
            'Eksperci precyzyjnie wyselekcjonowani metodą 10 punktów dopasowania® Connectis.',
          label: 'Zatrudnij Eksperta',
        },
        ourServices: {
          description: 'Połącz się z nami, aby zapewnić najlepsze wsparcie swoim projektom.',
          label: 'Nasze usługi',
        },
        iNeedAQuote: {
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          label: 'Potrzebuję wyceny',
        },
      },
    },
    experts: {
      heading: 'Dla Ekspertów',
      items: {
        techExperts: {
          description: 'Znajdź pracę, którą kochasz, w firmach, którym ufasz.',
          label: 'Dla Tech Ekspertów',
        },
        becomeConnectedExpert: {
          description: 'Dołącz do naszego grona najlepszych ekspertów i ekspertek IT.',
          label: 'Zostań Conntected Expert®',
        },
        jobOffers: {
          description: 'Odkrywaj oferty pracy i wybieraj projekty uznawanych klientów na rynku IT.',
          label: 'Oferty pracy',
        },
        cHub: {
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          label: 'C_hub',
        },
        referralProgram: {
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          label: 'Program poleceń',
        },
      },
    },
    whyConnectis: {
      heading: 'Dlaczego Connectis_',
      items: {
        caseStudies: {
          description:
            'Zobacz projekty będące efektem współpracy najlepszych ekspertów z topowymi firmami.',
          label: 'Case studies',
        },
        aboutUs: {
          description:
            'Tworzymy społeczność developerów i liderów napędzając technologię odwagą, zaufaniem i pasją.',
          label: 'O nas',
        },
        ambassadorProgram: {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in turpis rhoncus sem maximus feugiat id non orci.',
          label: 'Program ambasadorski',
        },
        contact: {
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          label: 'Kontakt',
        },
      },
    },
  },
  signInButton: {
    signIn: 'Zaloguj się',
    login: 'Logowanie',
    iAmExpert: 'Jestem ekspertem',
    iAmClient: 'Jestem klientem',
  },
  headerMenu: {
    jobOrders: 'Oferty pracy',
    getStarted: 'Get started',
  },
  portfolioProject: {
    seeProject: 'Zobacz projekt',
    airlines: {
      title: 'Aplikacja linii lotniczych',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  },
  bottomMenu: {
    officeConnectis: 'office@connectis.pl',
    privacy: 'Privacy',
    termsOfService: 'Terms of service',
  },
};
