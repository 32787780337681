import { Paper, SearchInput, Skill } from 'components';
import {
  englishLanguageId,
  LanguageSkillLevelType,
  languagesLevelDisc,
} from 'components/Onboarding/steps/LanguagesStep';
import { SkillLevel } from 'components/Onboarding/steps/SkillsStep';
import { ExpertLanguage, LanguageLevelEnum } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  findElementByKey,
  mapArrayIntoOptions,
  mapObjectInArray,
  removeObjectFromArray,
} from 'utils';
import { useOnboardingDataQuery } from '../../../../../../hooks/api/onboardingData/onboardingData.generated';

interface LanguagesStepValues {
  languages: ExpertLanguage[];
}

export const Languages = () => {
  const { t } = useTranslation();
  const { data } = useOnboardingDataQuery();
  const { languages } = data ?? { languages: [] };

  const { setValue, watch } = useFormContext<LanguagesStepValues>();
  const languagesValue = watch('languages') ?? [];

  const skillsOptions = mapArrayIntoOptions(
    languages.filter(
      ({ languageId }) => !findElementByKey(languagesValue, 'languageId', languageId),
    ),
    'name',
    'languageId',
  );

  const handleAddLanguage = (languageId: string) => {
    const updatedLanguages = [...languagesValue, { languageId, level: LanguageLevelEnum.Starter }];
    setValue('languages', updatedLanguages, { shouldValidate: true, shouldDirty: true });
  };

  const handleLanguageLevelChange = (languageId: string, newLevel: LanguageSkillLevelType) =>
    setValue(
      'languages',
      mapObjectInArray(
        languagesValue,
        (lang) => lang.languageId === languageId,
        (lang) => ({ ...lang, level: languagesLevelDisc[newLevel ?? 1] }),
      ),
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );

  const handleRemoveLanguage = (languageId: string) => {
    if (languageId === englishLanguageId) {
      return;
    }
    setValue('languages', removeObjectFromArray(languagesValue, 'languageId', languageId), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <Paper
      className="xl:min-w-[600px] !p-4 xl:!p-8"
      id="languages"
      title={t('profile:papers.languages.title')}
      titleClassName="text-base lg:text-lg !mb-4 lg:!mb-6"
    >
      <SearchInput
        label={t('profile:papers.languages.languagesInputLabel')}
        onChange={handleAddLanguage}
        options={skillsOptions}
        placeholder={t('profile:papers.languages.languagesInputPlaceholder')}
      />
      <div className="grid md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-2 md:gap-8 mt-6">
        {languagesValue?.map(({ languageId, level }) => (
          <Skill<LanguageSkillLevelType>
            key={`languageItem_${languageId}`}
            className="md:min-h-[125px] md:min-w-[130px]"
            dotsNumber={6}
            hideDeleteButton={languageId === englishLanguageId}
            id={languageId}
            level={Object.values(languagesLevelDisc).indexOf(level) as SkillLevel}
            levelDiscName="languageLevels"
            name={languages.find((lang) => lang.languageId === languageId)?.name ?? languageId}
            onLevelChange={handleLanguageLevelChange}
            onRemove={handleRemoveLanguage}
          />
        ))}
      </div>
    </Paper>
  );
};
