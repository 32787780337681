import { useScrollLock } from 'hooks';
import { AngleIcon } from 'icons';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { ConnectisWebsiteMenu, menuSchema } from '../../../configs';
import { ConnectisWebsiteHeaderLanguage } from '../ConnectisWebsiteHeaderLanguage';
import { ConnectisWebsiteHeaderLink } from '../ConnectisWebsiteHeaderLink';
import { ConnectisWebsiteMobileMenuItem } from './ConnectisWebsiteMobileMenuItem';
import { MobileMenuHeader } from './MobileMenuHeader';

interface ConnectisWebsiteMobileHeaderMenuProps {
  open: boolean;
  onClose: () => void;
}

export const ConnectisWebsiteMobileHeaderMenu: FC<ConnectisWebsiteMobileHeaderMenuProps> = ({
  open,
  onClose,
}) => {
  const nodeRef = useRef(null);
  const { lockPageScroll, unlockPageScroll } = useScrollLock();
  const [selectedItem, setSelectedItem] = useState<ConnectisWebsiteMenu | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { t } = useTranslation();

  const handleItemClick = (index: number) => {
    setSelectedItem(menuSchema[index]);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    onClose();
    setSelectedIndex(null);
  };

  useEffect(() => {
    if (open) {
      lockPageScroll();
      return;
    }
    unlockPageScroll();

    return unlockPageScroll;
  }, [open]);

  return (
    <>
      <CSSTransition classNames="fade" in={open} nodeRef={nodeRef} timeout={200} unmountOnExit>
        <div
          ref={nodeRef}
          className="flex xl:hidden fixed top-0 left-0 bg-white w-screen h-[100dvh] overflow-auto text-black px-4 py-6 flex-col justify-between z-40"
        >
          <MobileMenuHeader onClose={handleClose} />
          <ul className="space-y-6">
            {menuSchema.map(({ name }, index) => (
              <li
                key={`menu-item-${name}`}
                className="flex items-center text-2xl space-x-1 group cursor-pointer"
                onClick={() => handleItemClick(index)}
              >
                <span>{t(`connectisWebsite:menus.${name}.heading`)}</span>
                <AngleIcon className="w-6 -rotate-90" />
              </li>
            ))}
            <li>
              <ConnectisWebsiteHeaderLink
                href="https://connectis.pl/"
                label={t('connectisWebsite:headerMenu.jobOrders')}
              />
            </li>
            <li>
              <ConnectisWebsiteHeaderLink
                href="https://connectis.pl/"
                label={t('connectisWebsite:headerMenu.getStarted')}
              />
            </li>
          </ul>
          <div className="w-full">
            <div className="py-8 mt-8 border-t border-t-new-gray-100">
              <p className="text-gray-600 mb-8">{t('connectisWebsite:signInButton.login')}</p>
              <div className="space-y-6">
                <ConnectisWebsiteHeaderLink
                  arrow
                  href="https://connectis.pl/"
                  label={t('connectisWebsite:signInButton.iAmExpert')}
                />
                <ConnectisWebsiteHeaderLink
                  arrow
                  href="https://connectis.pl/"
                  label={t('connectisWebsite:signInButton.iAmClient')}
                />
              </div>
            </div>
            <ConnectisWebsiteHeaderLanguage className="text-primary-500" />
          </div>
        </div>
      </CSSTransition>
      <ConnectisWebsiteMobileMenuItem
        {...selectedItem}
        onBack={() => setSelectedIndex(null)}
        onClose={handleClose}
        open={selectedIndex !== null}
      />
    </>
  );
};
