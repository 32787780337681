import { FC } from 'react';

interface ExternalOfferHeaderDetailProps {
  label: string;
  value?: string | null;
}

export const ExternalOfferHeaderDetail: FC<ExternalOfferHeaderDetailProps> = ({ label, value }) => {
  return (
    <p>
      {label}: <span className="font-medium">{value || '-'}</span>
    </p>
  );
};
