import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type PublicOfferQueryVariables = Types.Exact<{
  offerId: Types.Scalars['String'];
  shouldCreateEvent?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type PublicOfferQuery = {
  __typename?: 'Query';
  publicOffer: {
    __typename?: 'PublicOfferWithJobOrderType';
    id: string;
    description: string;
    isRateDisplayed: boolean;
    jobOrderId: string;
    contextContactEmail?: string | null;
    jobOrder?: {
      __typename?: 'JobOrderType';
      isChubVisible: boolean;
      leadingTechnologyName?: string | null;
      rateRangeTo?: number | null;
      rateRangeFrom?: number | null;
      role?: string | null;
      locationPreference?: string | null;
      workMode?: Types.WorkModeEnum | null;
      seniority?: Types.SeniorityLevelEnum | null;
      minEnglishLevel?: Types.LanguageLevelEnum | null;
      dateEnd?: string | null;
      skillsMustHave: Array<{
        __typename?: 'ExpertTagType';
        id: string;
        name: string;
        weight: number;
      }>;
      skillsNiceToHave: Array<{
        __typename?: 'ExpertTagType';
        id: string;
        name: string;
        weight: number;
      }>;
    } | null;
    owner: {
      __typename?: 'CreatedByUserType';
      id?: string | null;
      name?: string | null;
      email?: string | null;
    };
  };
};

export const PublicOfferDocument = gql`
  query publicOffer($offerId: String!, $shouldCreateEvent: Boolean) {
    publicOffer(offerId: $offerId, shouldCreateEvent: $shouldCreateEvent) {
      id
      description
      isRateDisplayed
      jobOrderId
      contextContactEmail
      jobOrder {
        isChubVisible
        leadingTechnologyName
        rateRangeTo
        rateRangeFrom
        role
        locationPreference
        workMode
        seniority
        minEnglishLevel
        dateEnd
        skillsMustHave {
          id
          name
          weight
        }
        skillsNiceToHave {
          id
          name
          weight
        }
      }
      owner {
        id
        name
        email
      }
    }
  }
`;

/**
 * __usePublicOfferQuery__
 *
 * To run a query within a React component, call `usePublicOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicOfferQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      shouldCreateEvent: // value for 'shouldCreateEvent'
 *   },
 * });
 */
export function usePublicOfferQuery(
  baseOptions: Apollo.QueryHookOptions<PublicOfferQuery, PublicOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicOfferQuery, PublicOfferQueryVariables>(PublicOfferDocument, options);
}
export function usePublicOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicOfferQuery, PublicOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PublicOfferQuery, PublicOfferQueryVariables>(
    PublicOfferDocument,
    options,
  );
}
export type PublicOfferQueryHookResult = ReturnType<typeof usePublicOfferQuery>;
export type PublicOfferLazyQueryHookResult = ReturnType<typeof usePublicOfferLazyQuery>;
export type PublicOfferQueryResult = Apollo.QueryResult<
  PublicOfferQuery,
  PublicOfferQueryVariables
>;
export function refetchPublicOfferQuery(variables: PublicOfferQueryVariables) {
  return { query: PublicOfferDocument, variables: variables };
}
