import { DiscordIcon, FacebookIcon, InstagramIcon, LinkedInIcon } from 'icons';

export const socials = [
  {
    name: 'linkedIn',
    icon: LinkedInIcon,
    url: 'https://connectis.pl/',
  },
  {
    name: 'facebook',
    icon: FacebookIcon,
    url: 'https://connectis.pl/',
  },
  {
    name: 'instagram',
    icon: InstagramIcon,
    url: 'https://connectis.pl/',
  },
  {
    name: 'discord',
    icon: DiscordIcon,
    url: 'https://connectis.pl/',
  },
];
