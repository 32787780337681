import { useScrollTop } from 'hooks';
import { ConnectisLogo, MobileMenuIcon } from 'icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { IconButton } from '../../Buttons';
import { ConnectisWebsiteHeaderMenu, ConnectisWebsiteMobileHeaderMenu } from './subcomponents';
import {
  ConnectisWebsiteHeaderLanguage,
  ConnectisWebsiteHeaderLink,
  ConnectisWebsiteHeaderSignInButton,
} from './subcomponents/ConnectisWebsiteHeaderMenu/subcomponents';

interface ConnectisWebsiteHeaderProps {
  isLightBackground: boolean;
}

export const ConnectisWebsiteHeader: FC<ConnectisWebsiteHeaderProps> = ({
  isLightBackground = false,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'connectisWebsite:headerMenu',
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isScrollTop = useScrollTop();

  return (
    <header
      className={mergeClasses(
        'fixed top-0 left-0 w-screen z-40 transition-all duration-150',
        isLightBackground ? 'text-black' : 'text-white',
        isScrollTop
          ? 'backdrop-blur-2xl bg-white/10 xl:backdrop-blur-[unset] xl:bg-[unset]'
          : 'backdrop-blur-none xl:backdrop-blur-[unset]',
      )}
    >
      <div className="max-w-container-lg px-4 lg:px-12 mx-auto flex justify-between items-center">
        <a className="z-20" href="https://connectis.pl/">
          <ConnectisLogo className="w-[120px]" />
        </a>
        <ConnectisWebsiteHeaderMenu isScrollTop={isScrollTop} />
        <ConnectisWebsiteMobileHeaderMenu
          onClose={() => setIsMobileMenuOpen(false)}
          open={isMobileMenuOpen}
        />
        <div className="hidden xl:flex items-center space-x-6 z-20">
          <ConnectisWebsiteHeaderLink href="https://connectis.pl/" label={t('jobOrders')} />
          <ConnectisWebsiteHeaderLink href="https://connectis.pl/" label={t('getStarted')} />
          <ConnectisWebsiteHeaderSignInButton />
        </div>
        <ConnectisWebsiteHeaderLanguage className="hidden xl:flex" />
        <IconButton
          className="xl:hidden text-neon-green-500 my-6 p-0 translate-y-1"
          hover={false}
          icon={MobileMenuIcon}
          iconClassName="w-6"
          onClick={() => setIsMobileMenuOpen(true)}
        />
      </div>
    </header>
  );
};
