import { Amplify } from 'aws-amplify';

Amplify.configure({
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  mandatorySignIn: false,
  ssr: true,
  Auth: {
    storage: Amplify.Cache,
  },
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
    responseType: 'code',
  },
});
