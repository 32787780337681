import { Collapse } from 'components';
import { useAuth, useSitemap } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserPanelDropdownListItem } from './UserPanelDropdownListItem';

interface UserPanelDropdownProps {
  isOpen: boolean;
}

export const UserPanelDropdown: FC<UserPanelDropdownProps> = ({ isOpen }) => {
  const { t } = useTranslation();
  const { signOutFromCognito } = useAuth();
  const navigate = useNavigate();
  const sitemap = useSitemap();

  const handleLogout = () => signOutFromCognito();

  const discordUrl = process.env.REACT_APP_DISCORD_URL ?? 'https://discord.com/';

  const options = [
    {
      label: t('profile:profileHeader.editProfile'),
      onClick: () => navigate(sitemap.profile()),
    },
    {
      label: t('common:help'),
      onClick: () => window.open(discordUrl),
    },
    {
      label: t('common:actions.logout'),
      onClick: handleLogout,
      className: 'text-red-500',
    },
  ];

  return (
    <Collapse
      className="absolute top-12 left-0 w-full rounded-bl-[16px] rounded-br-[16px] overflow-hidden z-20 shadow-md-down"
      isCollapsed={!isOpen}
    >
      <ul className="bg-white py-2">
        {options.map((optProps) => (
          <UserPanelDropdownListItem
            key={`UserPanelDropdownListItem_${optProps.label}`}
            {...optProps}
          />
        ))}
      </ul>
    </Collapse>
  );
};
