import { PublicOfferAppliedJobOrdersQuery } from '../../../../hooks/api/publicOfferAppliedJobOrders/publicOfferAppliedJobOrders.generated';

const minScoreToApply = process.env.REACT_APP_MINIMAL_SCORE_TO_APPLY || 0;
export const calculatePublicOfferAppliedVisibleOffersNumber = (
  publicOfferAppliedJobOrders: PublicOfferAppliedJobOrdersQuery['publicOfferAppliedJobOrders'],
) => {
  let visibleJobOrders = 1;

  const jobOrdersToApply = publicOfferAppliedJobOrders.filter(
    ({ score }) => score.finalScore >= minScoreToApply,
  );

  if (jobOrdersToApply.length) {
    visibleJobOrders = jobOrdersToApply.length;
  }

  return Math.min(5, visibleJobOrders);
};
