import { GlobeIcon } from 'icons';
import { FC } from 'react';
import { mergeClasses } from 'utils';

export const ConnectisWebsiteHeaderLanguage: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={mergeClasses('flex items-center space-x-1.5 z-20', className)}>
      <GlobeIcon className="h-6 lg:w-3 h-6 lg:h-3" />
      <span className="text-2xl lg:text-sm">PL</span>
    </div>
  );
};
